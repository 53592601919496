<template>
    <div class="container-section intro subscription-options">
        <div class="wrapper">
            <div class="content-middle" id="app">
                <h2>{{translations.pageTitle}}</h2>
                <div class="nested-lg">
                    <div class="col-md-33 col-padding" v-for="option of options">
                        <label v-bind:for="option.id" @click="method_id = option.id">
                            <div class="subscription-item" @click="makeActive($event)" style="height: 195px;">
                                <div class="title" data-equal-group="subscription-title">
                                    <img :src="option.image" :srcset="option.image" :alt="option.description" style="margin-bottom: 10px;"/>
                                    <h3><input type="radio" name="subscription_type" v-bind:id="option.id" :checked="method_id === option.id ? 'checked' : false" />  {{option.description}} ({{option.id}})</h3>
                                </div>
                                <select v-if="option.id === 'ideal'" class="form-control form-block" id="ideal_issuers">
                                    <option v-for="issuer of ideal_issuers" :value="issuer.id">{{issuer.name}}</option>
                                </select>
                            </div>
                        </label>
                    </div>
                    <a class="btn btn-secondary" style="margin-top: 30px;" v-on:click="startPayment(method_id)">{{translations.start_payment}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "PaymentOptions",
    data() {
        return {
            translations: {pageTitle: 'Loading...'},
            options: [],
            ideal_issuers: [],
            ideal: true,
            method_id: null
        }
    },
    methods: {
        makeActive(event) {
            document.querySelectorAll('.subscription-item').forEach((elem) => {
                elem.classList.remove('active');
            });

            event.target.closest('.subscription-item').classList.add('active');
        },
        startPayment(id) {
            let formData = new FormData();
            formData.append('method', id);
            if (id === 'ideal') {
                formData.append('issuer', document.getElementById('ideal_issuers').value);
            }
            axios.post('ajax/create-payment?language=' + document.documentElement.lang, formData)
                .then(response => {
                    if (response.data.status === 200 && response.data.url !== null) {
                        location.href = response.data.url;
                    } else {
                        this.error(response.data.status);
                        console.error(`Something went wrong during AJAX request. `, response);
                    }
                })
                .catch(e => {
                    this.error();
                    console.error(`Something went wrong during AJAX request: ${e}`);
                });
        },
        error(msg) {
            Swal.fire(this.translations.error_title, (msg === undefined ? this.translations.error_text : msg), 'error');
        }
    },
    beforeCreate() {
        axios.get('ajax/payment-options?language=' + document.documentElement.lang)
            .then(response => {
                if (response.status === 200) {
                    this.translations = response.data.translations;
                    this.options = response.data.methods;
                    this.ideal_issuers = response.data.ideal_issuers;
                } else {
                    this.error();
                    console.error(`Something went wrong during AJAX request.`);
                }
            })
            .catch(e => {
                this.error();
                console.error(`Something went wrong during AJAX request: ${e}`);
            });
    }
}
</script>

<style scoped>
input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #caab8e;
    content: '';
    display: inline-block;
    visibility: visible;
}

.d-none {
    display: none !important;
}

.subscription-item input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
</style>
