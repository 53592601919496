<template>
    <div>
        <div class="step-item" v-for="(step, index) of steps">
            <div class="nested">
                <div class="col-lg-5 col-padding">
                    <div class="step-number">{{step.configuration.title}}</div>
                </div>

                <div class="col-lg-85 col-padding">
                    <div class="left">
                        <img v-bind:src="step.configuration.default_image" alt="" class="step-image" id="image-1">
                    </div>

                    <div class="left">
                        <div class="step-title">{{step.configuration.slider_title}}</div>
                        <p>{{step.configuration.slider_text}}</p>
                        <input type="range" min="1" v-bind:max="step.configuration.snap ? step.slider_steps.length : 100" value="1" class="slider" :data-step="index" :name="'slider_'+index" v-on:change="rangeUpdate" ref="rangeSlider" v-slider="index">
                        <div class="row">
                            <div class="col" v-for="(label, index) of step.slider_steps" v-bind:class="{'text-center': index > 0 && index !== step.slider_steps.length-1, 'text-right': index === step.slider_steps.length-1}" @click="positionRange($event, index)"><span style="cursor: pointer">{{label.title}}</span></div>
                        </div>

                    </div>
                </div>

                <div class="col-lg-10 col-padding" v-if="isLoggedIn === false">
                    <a class="btn btn-primary" nohref @click="saveStep(index)">{{translations.next}} <i class="icon icon-chevron-thin-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "image-slider",
    props: {
        url: String
    },
    data() {
        return {
            isLoggedIn: false,
            steps: [],
            values: null,
            translations: {save: "Loading..."}
        }
    },
    methods: {
        rangeUpdate(event) {
            let amountOfImages = this.steps[event.target.dataset.step].slider_steps.length;
            let stepsPerImage;
            if (this.steps[event.target.dataset.step].configuration.snap === true) {
                stepsPerImage = 1;
            } else {
                stepsPerImage = 100 / amountOfImages;
            }

            for (let i = 0; i <= amountOfImages; i++) {
                let curStep = stepsPerImage * i;
                let nextStep = curStep + stepsPerImage;
                if (event.target.value >= curStep && event.target.value <= nextStep) {
                    this.steps[event.target.dataset.step].configuration.default_image = this.steps[event.target.dataset.step].slider_steps[i].image;
                    break;
                }
            }
        },
        positionRange(event, index) {
            // Find the corresponding slider
            let slider = event.target.parentNode.parentNode.parentNode.childNodes[4];

            // Set the correct image
            this.steps[slider.dataset.step].configuration.default_image = this.steps[slider.dataset.step].slider_steps[index].image;

            // Calculate the value that the slider should be
            let totalSteps = this.steps[slider.dataset.step].slider_steps.length;
            let stepsPerImage;
            if (this.steps[slider.dataset.step].configuration.snap === true) {
                stepsPerImage = 1;
            } else {
                stepsPerImage = 100 / totalSteps;
            }

            let sliderVal;
            switch (index) {
                case 0: {
                    sliderVal = 1;
                    break;
                }
                case this.steps[slider.dataset.step].slider_steps.length - 1: {
                    sliderVal = 100;
                    break;
                }
                default: {
                    sliderVal = (stepsPerImage * index) + (stepsPerImage / 2)
                }
            }

            // Set the value of the range slider
            slider.value = sliderVal;
        },
        saveStep(index) {
            for (let slider of this.$refs.rangeSlider) {
                location.href = this.url + '?step1=' + slider.value;
            }
        },
        error(msg) {
            this.$notify({
                group: 'notify',
                type: 'error',
                title: this.translations.error_title,
                text: msg === undefined ? this.translations.error_text : msg
            });
        }
    },
    directives: {
        slider: {
            inserted: (el, binding, vnode) => {
                if (vnode.context.values !== null) {
                    const steps = document.getElementsByClassName('step-item');
                    steps[binding.value].classList.remove('next-item');
                    steps[binding.value].classList.remove('active-item');
                    steps[steps.length - 1].classList.add('active-item');
                    el.value = vnode.context.values[binding.value];
                }
            }
        }
    },
    beforeCreate() {
        axios.get('ajax/blend-steps?language=' + document.documentElement.lang)
            .then(response => {
                if (response.status === 200) {
                    this.steps.push(response.data.steps[0]);
                    this.translations = response.data.translations;
                    if (response.data.values !== undefined) {
                        this.values = response.data.values;
                    }
                } else {
                    this.error();
                    console.error(`Something went wrong during AJAX request.`);
                }
            })
            .catch(e => {
                this.error();
                console.error(`Something went wrong during AJAX request: ${e}`);
            });
    }
}
</script>

<style scoped>
* {
    outline: none;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: transparent;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border: solid 1px #391b15;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #391b15;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #391b15;
    cursor: pointer;
}

.btn-primary {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #391b15;
    border: none;
    border-radius: 12px;
    padding: 5px 30px 5px 30px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}
</style>
