<template>
    <div class="container" style="margin-top: 50px;">
        <div style="margin-top: 50px; display: flex; align-items: end" v-if="isLoggedIn === true && new_coffee === null">
            <a class="btn btn-primary" nohref @click="saveInAccountAndPlaceOrder()">{{translations.order}} <i class="icon icon-save"></i></a>
            <a class="btn btn-primary" nohref @click="saveInAccount()">{{translations.save}} <i class="icon icon-save"></i></a>
        </div>

        <template v-if="new_coffee !== null">
            <div class="content-full">
                <div class="nested-lg">
                    <div class="col-lg-50 col-padding-lg">
                        <img :src="new_coffee.image" alt="Create your own coffee">
                    </div>

                    <div v-if="place_order === false" class="col-lg-50 col-padding-lg">
                        <h1>{{new_coffee.taste}}</h1>
                        <span v-html="new_coffee.description"></span>
                    </div>
                    <div v-else class="col-xs-50 col-padding-lg" style="z-index: 9999">
                        <h5 class="mb-2">{{new_coffee.taste}}</h5>
                        <div class="col-md-100">
                            <input type="number" min="0" class="number-input" v-model="new_order.amount"> <span style="">{{translations.units}}</span>
                            <div style="display: inline-block; margin-left: 5px;">
                                <select class="form-control" style="height: 38px;" v-model="new_order.unit">
                                    <option v-for="(unit, amount) of units" :value="amount">{{unit.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="place_order" class="nested-lg subscription-options">
                    <div class="col-xs-100 col-padding-lg pb-0">
                        <h3>{{translations.order}}</h3>
                    </div>
                    <div class="col-md-33 col-padding-lg" v-for="option of options">
                        <label v-bind:for="option.id" @click="new_order.method_id = option.id">
                            <div class="subscription-item" @click="makeActive($event)" style="height: 195px;">
                                <div class="title" data-equal-group="subscription-title">
                                    <img :src="option.image" :srcset="option.image" :alt="option.description" style="margin-bottom: 10px;"/>
                                    <h3><input type="radio" name="subscription_type" v-bind:id="option.id" :checked="new_order.method_id === option.id ? 'checked' : false" />  {{option.description}} ({{option.id}})</h3>
                                </div>
                                <select v-if="option.id === 'ideal'" class="form-control form-block" id="ideal_issuers" v-model="new_order.ideal_issuer">
                                    <option v-for="issuer of ideal_issuers" :value="issuer.id">{{issuer.name}}</option>
                                </select>
                            </div>
                        </label>
                    </div>
                    <div class="col-xs-100 col-padding-lg">
                        <a class="btn btn-secondary" style="margin-top: 30px;" v-on:click="startPayment()">{{translations.start_payment}}</a>
                    </div>
                </div>
            </div>
        </template>

        <div v-else class="step-item" v-for="(step, index) of steps" v-bind:class="{'active-item': index === 0 && isLoggedIn === false}" v-show="isLoggedIn === true? true : (index >= steps.length-1)">
            <div class="nested">
                <div class="col-lg-5 col-padding">
                    <div class="step-number">{{step.configuration.title}}</div>
                </div>
                <div class="col-lg-85 col-padding">
                    <div class="left">
                        <img v-bind:src="step.configuration.default_image" alt="" class="step-image" id="image-1">
                    </div>
                    <div class="left">
                        <div class="step-title">{{step.configuration.slider_title}}</div>
                        <p>{{step.configuration.slider_text}}</p>
                        <input type="range" min="1" v-bind:max="step.configuration.snap ? step.slider_steps.length : 100" value="1" class="slider" :data-step="index" :name="'slider_'+index" v-on:change="rangeUpdate" ref="rangeSlider" v-slider="index">
                        <div class="row steps-small">
                            <div class="col" v-for="(label, index) of step.slider_steps" v-bind:class="{'text-center': index > 0 && index !== step.slider_steps.length-1, 'text-right': index === step.slider_steps.length-1}" @click="positionRange($event, index)"><span style="cursor: pointer">{{label.title}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10 col-padding" v-if="isLoggedIn === false">
                    <a class="btn btn-primary" nohref @click="nextItem(index)">{{index !== 0 ? translations.next : translations.cta}} <i class="icon icon-chevron-thin-right"></i></a>
                </div>
            </div>
        </div>

        <div style="margin-top: 50px" v-if="isLoggedIn === true && new_coffee === null && place_order === false">
            <a class="btn btn-primary" nohref @click="saveInAccount()">{{translations.save}} <i class="icon icon-save"></i></a>
        </div>

        <div v-if="admin.isAdmin" style="position:fixed; bottom: 20px; right: 20px; background-color: royalblue; padding: 30px; color: #ffffff">
            <table>
                <tr>
                    <td>Coffee</td>
                    <td>Score</td>
                </tr>
                <tr v-for="score of admin.calculation">
                    <td>{{score[0]}}</td>
                    <td>{{score[1]}}</td>
                </tr>
            </table>
            <hr />
            {{admin.personalCoffee}}
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "image-slider",
        props: {
            url: String,
        },
        data() {
            return {
                admin: {
                    isAdmin: false,
                    personalCoffee: null,
                    calculation: null
                },
                isLoggedIn: false,
                steps: [],
                values: [],
                translations: {save: "Loading..."},

                new_coffee: null,
                place_order: false,

                new_order: {
                    amount: 1,
                    unit: 250,
                    method_id: null,
                    ideal_issuer: null,
                },

                units: {
                    250: {
                        amount: 9.95,
                        label: '250 g',
                    },
                    700: {
                        amount: 21.95,
                        label: '700 g',
                    },
                    2000: {
                        amount: 54.95,
                        label: '2 kg'
                    },
                },

                options: [],
                ideal_issuers: [],
            }
        },
        methods: {
            startPayment() {
                const formData = new FormData();

                formData.append('amount', this.new_order.amount);
                formData.append('unit', this.new_order.unit);
                formData.append('method_id', this.new_order.method_id);

                if (this.new_order.method_id === 'ideal') {
                    formData.append('ideal_issuer', this.new_order.ideal_issuer);
                }

                axios.post('ajax/user-create-payment?language=' + document.documentElement.lang, formData)
                    .then(response => {
                        if (response.status === 200 && response.data.url !== null) {
                            location.href = response.data.url;
                        } else {
                            this.error(response.data.status);
                            console.error(`Something went wrong during AJAX request. `, response);
                        }
                    })
                    .catch(e => {
                        this.error();
                        console.error(`Something went wrong during AJAX request: ${e}`);
                    });
            },
            makeActive(event) {
                document.querySelectorAll('.subscription-item').forEach((elem) => {
                    elem.classList.remove('active');
                });

                event.target.closest('.subscription-item').classList.add('active');
            },
            calculateProduct() {
                let formData = new FormData();
                for (let slider of this.$refs.rangeSlider) {
                    formData.append('steps['+slider.dataset.step+']', slider.value);
                }

                axios.post('ajax/admin-calculate-coffee', formData)
                .then(response => {
                    if (response.data !== null) {
                        this.admin.isAdmin = true;
                        this.admin.personalCoffee = response.data.coffee.code;

                        let sortable = [];
                        for (let item in response.data.scores) {
                            sortable.push([item, response.data.scores[item]]);
                        }

                        this.admin.calculation = sortable.sort((a, b) => {
                           return a[1] - b[1];
                        }).reverse();
                    }
                })
                .catch(e => {
                    console.error(e);
                })
            },
            rangeUpdate(event) {
                let amountOfImages = this.steps[event.target.dataset.step].slider_steps.length;
                let stepsPerImage;
                if (this.steps[event.target.dataset.step].configuration.snap === true) {
                    stepsPerImage = 1;
                }else {
                    stepsPerImage = 100 / amountOfImages;
                }

                for (let i = 0; i <= amountOfImages; i++) {
                    let curStep = stepsPerImage * i;
                    let nextStep = curStep + stepsPerImage;
                    if (event.target.value >= curStep && event.target.value <= nextStep) {
                        this.steps[event.target.dataset.step].configuration.default_image = this.steps[event.target.dataset.step].slider_steps[i].image;
                        break;
                    }
                }

                this.calculateProduct();
            },
            positionRange(event, index) {
                // Find the corresponding slider
                let slider = event.target.parentNode.parentNode.parentNode.childNodes[4];

                // Set the correct image
                this.steps[slider.dataset.step].configuration.default_image = this.steps[slider.dataset.step].slider_steps[index].image;

                // Calculate the value that the slider should be
                let totalSteps = this.steps[slider.dataset.step].slider_steps.length;
                let stepsPerImage;
                if (this.steps[slider.dataset.step].configuration.snap === true) {
                    stepsPerImage = 1;
                }else {
                    stepsPerImage = 100 / totalSteps;
                }

                let sliderVal;
                switch (index) {
                    case 0: {
                        sliderVal = 1;
                        break;
                    }
                    case this.steps[slider.dataset.step].slider_steps.length-1: {
                        sliderVal = 100;
                        break;
                    }
                    default: {
                        sliderVal = (stepsPerImage * index) + (stepsPerImage / 2)
                    }
                }

                // Set the value of the range slider
                slider.value = sliderVal;

                this.calculateProduct();
            },
            nextItem(index) {
                let elements = document.getElementsByClassName('step-item');
                if (index > 0) {
                    elements[index].classList.remove('active-item');
                    if (elements.length > index-1) {
                        elements[index-1].style.display = 'block';
                        elements[index-1].classList.add('active-item');
                    }
                }else {
                    let formData = new FormData();
                    for (let slider of this.$refs.rangeSlider) {
                        formData.append('steps['+slider.dataset.step+']', slider.value);
                    }

                    // The steps are done so save data to session
                    axios.post('ajax/save-blend-steps', formData)
                    .then(response => {
                        if (response.data.status === 200) {
                            location.href = this.url;
                        }else {
                            this.error(response.data.status);
                            console.error(`Something went wrong during AJAX request.`);
                        }
                    })
                    .catch(e => {
                        this.error();
                        console.error(`Something went wrong during AJAX request: ${e}`);
                    })
                }

            },
            saveInAccount() {
                let formData = new FormData();
                for (let slider of this.$refs.rangeSlider) {
                    formData.append('steps['+slider.dataset.step+']', slider.value);
                }

                axios.post('ajax/update-blend-steps?language=' + document.documentElement.lang, formData)
                .then(response => {
                    if (response.data.status === 200) {
                        this.new_coffee = response.data.coffee;
                    }else {
                        this.error(response.data.status);
                        console.error(`Something went wrong during AJAX request.`);
                    }
                })
                .catch(e => {
                    this.error();
                    console.error(`Something went wrong during AJAX request: ${e}`);
                })
            },
            saveInAccountAndPlaceOrder() {
                let formData = new FormData();
                for (let slider of this.$refs.rangeSlider) {
                    formData.append('steps['+slider.dataset.step+']', slider.value);
                }

                axios.post('ajax/update-blend-steps?language=' + document.documentElement.lang, formData)
                .then(response => {
                    if (response.data.status === 200) {
                        this.new_coffee = response.data.coffee;
                        this.place_order = true;
                    }else {
                        this.error(response.data.status);
                        console.error(`Something went wrong during AJAX request.`);
                    }
                })
                .catch(e => {
                    this.error();
                    console.error(`Something went wrong during AJAX request: ${e}`);
                })
            },
            error(msg) {
                Swal.fire(this.translations.error_title, (msg === undefined ? this.translations.error_text : msg), 'error');
            },
            setImage(index) {
                let amountOfImages = this.steps[index].slider_steps.length;
                let stepsPerImage;
                if (this.steps[index].configuration.snap === true) {
                    stepsPerImage = 1;
                }else {
                    stepsPerImage = 100 / amountOfImages;
                }

                for (let i = 0; i <= amountOfImages; i++) {
                    let curStep = stepsPerImage * i;
                    let nextStep = curStep + stepsPerImage;
                    if (this.values[index] >= curStep && this.values[index] <= nextStep) {
                        this.steps[index].configuration.default_image = this.steps[index].slider_steps[i].image;
                        break;
                    }
                }
            }
        },
        directives: {
            slider: {
                inserted: (el, binding, vnode) => {
                    if (vnode.context.values !== null) {
                        const steps = document.getElementsByClassName('step-item');
                        steps[binding.value].classList.remove('next-item');
                        steps[binding.value].classList.remove('active-item');
                        steps[steps.length-1].classList.add('active-item');
                        el.value = vnode.context.values[binding.value];
                    }
                }
            }
        },
        mounted() {
            setTimeout(() => {
                for (let i = 0; i < this.$refs.rangeSlider.length; i++) {
                    let slider = this.$refs.rangeSlider[i];

                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const step1 = urlParams.get('step1');
                    if (step1 !== null) {
                        if (slider.getAttribute("name").split("_")[1] !== String(this.steps.length-1)) {
                            if (this.values.length < 1 || this.values[i] === undefined) {
                                slider.value = 0;
                            }else {
                                this.setImage(i);
                                document.getElementsByClassName('step-item')[i].style.display = "block";
                            }
                        }

                        this.nextItem(8);
                    }else {
                        if (this.values !== null) {
                            if (this.values.length < 1 || this.values[i] === undefined) {
                                slider.value = 0;
                            }else {
                                this.setImage(i);
                                document.getElementsByClassName('step-item')[i].style.display = "block";
                            }
                        }
                    }
                }
            }, 500);
        },
        beforeCreate() {
            axios.get('ajax/blend-steps?language=' + document.documentElement.lang)
            .then(response => {
                if (response.status === 200) {
                    this.steps = response.data.steps.slice().reverse();
                    this.translations = response.data.translations;
                    if (response.data.values !== undefined) {
                        this.values = response.data.values;
                    }

                    // Check if a param has been set
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const step1 = urlParams.get('step1');
                    if (step1 !== null) {
                        this.values[this.steps.length-1] = step1;

                        let amountOfImages = this.steps[this.steps.length-1].slider_steps.length;
                        let stepsPerImage;
                        if (this.steps[this.steps.length-1].configuration.snap === true) {
                            stepsPerImage = 1;
                        }else {
                            stepsPerImage = 100 / amountOfImages;
                        }

                        for (let i = 0; i <= amountOfImages; i++) {
                            let curStep = stepsPerImage * i;
                            let nextStep = curStep + stepsPerImage;
                            if (step1 >= curStep && step1 <= nextStep) {
                                this.steps[this.steps.length-1].configuration.default_image = this.steps[this.steps.length-1].slider_steps[i].image;
                                break;
                            }
                        }
                    }

                    // If the user is logged in load blend steps and set the logged in bool to true
                    if (response.data.user !== undefined) {
                        this.isLoggedIn = response.data.user.isLoggedIn;
                        this.values = response.data.user.values;
                        //todo: set the image for each slider :)
                    }
                }else {
                    this.error();
                    console.error(`Something went wrong during AJAX request.`);
                }
            })
            .catch(e => {
                this.error();
                console.error(`Something went wrong during AJAX request: ${e}`);
            });

            axios.get('ajax/payment-options?language=' + document.documentElement.lang)
                .then(response => {
                    if (response.status === 200) {
                        // this.translations = response.data.translations;
                        this.options = response.data.methods;
                        this.ideal_issuers = response.data.ideal_issuers;
                    } else {
                        this.error();
                        console.error(`Something went wrong during AJAX request.`);
                    }
                })
                .catch(e => {
                    this.error();
                    console.error(`Something went wrong during AJAX request: ${e}`);
                });
        }
    }
</script>

<style scoped>
    * {
        outline: none;
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: transparent;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        border: solid 1px #391b15;
    }

    .slider:hover {
        opacity: 1;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #391b15;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #391b15;
        cursor: pointer;
    }

    .btn-primary {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #391b15;
        border: none;
        border-radius: 12px;
        padding: 5px 30px 5px 30px;
    }

    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .col {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px
    }
    .col {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .number-input {
        margin-bottom: 15px;
        border: 0.5px solid rgba(112, 112, 112, 0.5);
        border-radius: 3px;
        width: 50px;
        padding: 10px;
        margin-right: 10px;
    }

    .number-input:disabled {
        background-color: rgba(168, 168, 168, 0.92);
    }
</style>
