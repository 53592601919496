<template>
    <div class="container-section">
        <div class="wrapper">

            <div class="content-middle" id="app">
                <h2>{{translations.pageTitle}}</h2>

                <div class="nested-lg">

                    <div class="col-md-50 col-padding-lg">
                        <ul class="grid">
                            <li><strong>{{translations.order_for}}</strong></li>
                            <li>{{ shipping_data.values.name }}</li>
                            <li>{{ shipping_data.values.address }}</li>
                            <li>{{ shipping_data.values.zip }}, {{ shipping_data.values.city }}</li>
                            <li>{{ shipping_data.values.country }}</li>
                            <li>{{shipping_data.values.email}}</li>
                        </ul>
                    </div>

                    <div class="col-md-50 col-padding-lg">
                        <ul class="grid" v-if="shipping_data.values.shipping_address !== null && shipping_data.values.shipping_address !== ''">
                            <li><strong>{{translations.shipping}}</strong></li>
                            <li>{{ shipping_data.values.name }}</li>
                            <li>{{ shipping_data.values.shipping_address }}</li>
                            <li>{{shipping_data.values.shipping_zip}}, {{shipping_data.values.shipping_city}}</li>
                            <li>{{shipping_data.values.country}}</li>
                        </ul>
                        <ul class="grid" v-else>
                            <li><strong>{{translations.shipping}}</strong></li>
                            <li>{{ shipping_data.values.name }}</li>
                            <li>{{ shipping_data.values.address }}</li>
                            <li>{{ shipping_data.values.zip }}, {{ shipping_data.values.city }}</li>
                            <li>{{ shipping_data.values.country }}</li>
                        </ul>
                    </div>
                </div>

                <div class="nested order-overview">
                    <div class="col-xs-20 col-padding">
                        <img :src="coffee.image" alt="">
                    </div>

                    <div class="col-xs-50 col-padding">
                        <h5>{{coffee.taste}}</h5>
                        {{subscription.amount}} x {{subscription.unit}} KG
                    </div>

                    <div class="col-xs-30 col-padding text-right">
                        <span v-if="subscription.type === 'once'" style="text-transform: capitalize; text-align: right">{{subscription.type}}</span>
                        <span v-else style="text-transform: capitalize; text-align: right">{{subscription.type}} {{translations.delivery}}</span>

                        <div class="price">
                            &euro; {{ price }}
                        </div>
                    </div>
                </div>


                <form v-on:submit.prevent="save()">
                    <div class="nested v-padding-xl">
                        <div class="col-md-50" style="margin-top: 10px;">
                            <label class="checkbox-parent" for="checkbox-lg">
                                <input id="checkbox-lg" class="check-control" type="checkbox" required />
                                <div class="checkbox-placeholder form-lg"></div>
                                {{translations.terms}} *
                            </label>
                        </div>

                        <div class="col-md-50" style="margin-top: 10px;">
                            <button class="btn btn-secondary" style="float: right;" type="submit">{{translations.place}}</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Overview",
    props: {
        url: String
    },
    data() {
        return {
            translations: {pageTitle: "Loading..."},
            shipping_data: {
                values: {
                    name: "Loading...",
                    address: "Loading...",
                    zip: "Loading...",
                    company: "Loading...",
                    city: "Loading...",
                    country: "Loading...",
                    shipping_address: "Loading...",
                    shipping_zip: "Loading...",
                    shipping_state: "Loading...",
                    shipping_city: "Loading...",
                    shipping_country: "Loading...",
                    email: "Loading..."
                }
            },
            coffee: null,
            subscription: null,
            price: null,
        }
    },
    methods: {
        save() {
            location.href = this.url;
        },
        error(msg) {
            Swal.fire(this.translations.error_title, (msg === undefined ? this.translations.error_text : msg), 'error');
        }
    },
    beforeCreate() {
        const shipping_info = axios.get("ajax/shipping-info?language=" + document.documentElement.lang);
        const personal_coffee = axios.get("ajax/personal-coffee?language=" + document.documentElement.lang);
        const subscription = axios.get("ajax/subscription?language=" + document.documentElement.lang);
        const overview = axios.get('ajax/overview?language=' + document.documentElement.lang);

        axios.all([shipping_info, personal_coffee, subscription, overview]).then(axios.spread((...responses) => {
            this.shipping_data = responses[0].data.form;
            this.coffee = responses[1].data.coffee;
            this.subscription = responses[2].data.subscription;
            this.price = responses[2].data.price;
            this.translations = responses[3].data.translations;
        })).catch(e => {
            this.error();
            console.error(`Something went wrong during AJAX request: ${e}`);
        });
    }
}
</script>

<style scoped>

</style>
