<template>
    <div>
        <div class="container-section intro result">
        <div class="wrapper">

            <div class="content-full">
                <div class="nested-lg">
                    <div class="col-lg-50 col-padding-lg">
                        <img :src="coffee.image" alt="Create your own coffee">
                    </div>

                    <div class="col-lg-50 col-padding-lg">
                        <h3>{{pageTitle}}</h3>
                        <h1>{{coffee.taste}}</h1>

                        <span v-html="coffee.description"></span>

                        <div class="v-padding-xl">
                            <h4>{{translations.order_text}}</h4>

                            <div class="row" style="width: 290px; margin-bottom: 20px;">
                                <div class="price">
                                    <ul>
                                        <li v-for="(price, amount) of pricing">{{amount}}g. &euro;{{price}}</li>
                                    </ul>
                                </div>
                            </div>

                            <button class="btn btn-secondary" @click="next">{{translations.button_text}}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "PersonalCoffee",
    props: {
        url: String
    },
    data() {
        return {
            translations: [],
            pageTitle: 'Loading...',
            amount: 1,
            coffee: {
                id: 0,
                taste: "Loading...",
                unit_price: 0,
                description: "Loading...",
                image: "frontend/img/placeholder.png"
            },
            pricing: []
        }
    },
    methods: {
        next() {
            location.href = this.url;
        },
        error(msg) {
            Swal.fire(this.translations.error_title, (msg === undefined ? this.translations.error_text : msg), 'error');
        }
    },
    created() {
        axios.get('ajax/personal-coffee?language=' + document.documentElement.lang)
            .then(response => {
                if (response.status === 200 && response.data.pageTitle !== undefined) {
                    this.translations = response.data.translations;
                    this.pageTitle = response.data.pageTitle;
                    this.coffee = response.data.coffee;
                    this.pricing = response.data.pricing;
                } else {
                    this.error();
                    console.error(`Something went wrong during AJAX request.`);
                }
            })
            .catch(e => {
                this.error();
                console.error(`Something went wrong during AJAX request: ${e}`);
            });
    }
}
</script>
