<template>
    <div class="container-section intro subscription-options">
        <div class="wrapper">
            <div class="content-middle" id="app">
                <h1>{{translations.pageTitle}}</h1>

                <div class="nested">
                    <h4>{{translations.pageSubTitle}}</h4>

                    <div class="col-md-100 col-padding" v-if="translations.pagePreview !== ''" v-html="translations.pagePreview"></div>

                    <div class="row d-flex" style="margin-bottom: 20px;">
                        <div class="nested order-overview ml-auto">
                            <div class="col-xs-20 col-padding">
                                <img :src="coffee.image" alt="">
                            </div>

                            <div class="col-xs-50 col-padding">
                                <h5>{{coffee.taste}}</h5>
                                <div class="col-md-100 col-padding">
                                    <input type="number" min="0" class="number-input" v-model="subscription.amount"> <span style="">{{translations.units}}</span>
                                    <div style="display: inline-block; margin-left: 5px;">
                                        <select class="form-control" style="height: 38px;" v-model="subscription.unit">
                                            <option v-for="(unit, amount) of units" :value="amount">{{unit.label}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-33 col-padding">
                        <label for="once">
                            <div class="subscription-item" :class="{active: subscription.type === 'once'}">
                                <div>
                                    <div class="title">
                                        <input type="radio" id="once" name="subscription_type" @click="subscription.type = 'once'" :checked="subscription.type === 'once'" />
                                        {{translations.just_this_order}}
                                        <p class="text-small text-muted">{{ translations.just_this_order_text.replace(':attribute', units[subscription.unit].label) }}</p>
                                    </div>
                                    <div class="row price">
                                        &euro;{{price['once']}} <span style="font-size: 13px; padding-left: 5px;">{{translations.once}}</span>
                                    </div>
                                    <div class="row">
                                        <span>{{translations.just_this_order_subtext}}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="col-md-33 col-padding">
                        <label for="monthly">
                            <div class="subscription-item" :class="{active: subscription.type === 'monthly'}">
                                <div>
                                    <div class="title">
                                        <input type="radio" id="monthly" name="subscription_type" @click="subscription.type = 'monthly'" :checked="subscription.type === 'monthly'" />
                                        {{translations.monthly}}
                                        <p class="text-small text-muted">{{ translations.monthly_text.replace(':attribute', units[subscription.unit].label) }}</p>
                                    </div>
                                    <div class="row price">
                                        &euro;{{price['monthly']}} <span style="font-size: 13px; padding-left: 5px;">{{translations.pmonth}}</span>
                                    </div>
                                    <div class="row">
                                        <span>{{translations.monthly_subtext}}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="col-md-33 col-padding">
                        <label for="weekly">
                            <div class="subscription-item" :class="{active: subscription.type === 'weekly'}">
                                <div>
                                    <div class="title">
                                        <input type="radio" id="weekly" name="subscription_type" @click="subscription.type = 'weekly'" :checked="subscription.type === 'weekly'" />
                                        {{translations.weekly}}
                                        <p class="text-small text-muted">{{ translations.weekly_text.replace(':attribute', units[subscription.unit].label) }}</p>
                                    </div>
                                    <div class="row price">
                                        &euro;{{price['weekly']}} <span style="font-size: 13px; padding-left: 5px;">{{translations.pweek}}</span>
                                    </div>
                                    <div class="row">
                                        <span>{{translations.weekly_subtext}}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="row">
                    <form @submit.prevent="save">
                        <div v-if="subscription.type !== 'once'" style="margin-bottom: 20px;">
                            <label class="checkbox-parent" for="checkbox-lg">
                                <input type="checkbox" class="check-control" id="checkbox-lg" required />
                                <div class="checkbox-placeholder form-lg"></div>
                                {{translations.withdrawal}} *
                            </label>
                            <div>
                                <span style="font-size: 13px; padding-top: 10px padding-left: 5px;">* {{translations.contact}}</span>
                            </div>
                        </div>

                        <div class="right">
                            <button class="btn btn-secondary" style="float: right;" type="submit">{{translations.check}}</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Subscription",
    props: {
        url: String
    },
    data() {
        return {
            translations: {pageTitle: "Loading..."},

            units: {
                250: {
                    amount: 9.95,
                    label: '250 g',
                },
                700: {
                    amount: 21.95,
                    label: '700 g',
                },
                2000: {
                    amount: 54.95,
                    label: '2 kg'
                },
            },

            pricing: null,

            coffee: {unit_price: 0},
            subscription: {
                amount: 1,
                unit: 250,
                type: 'once'
            },
        }
    },
    computed: {
        price() {
            if (this.pricing === null) {
                return 0;
            }

            let pricing = {};

            for (const item of ['once', 'weekly', 'monthly']) {
                pricing[item] = (parseFloat(this.pricing[item][this.subscription.unit]) * this.subscription.amount).toFixed(2)
            }

            return pricing;
        }
    },
    methods: {
        save() {
            let formData = new FormData();
            formData.append('amount', this.subscription.amount);
            formData.append('unit', this.subscription.unit);
            formData.append('type', this.subscription.type);
            axios.post('ajax/save-subscription', formData)
                .then(response => {
                    if (response.data.status === 200) {
                        location.href = this.url;
                    } else {
                        this.error(response.data.status);
                        console.error(`Something went wrong during AJAX request.`);
                    }
                })
                .catch(e => {
                    this.error();
                    console.error(`Something went wrong during AJAX request: ${e}`);
                });
        },
        setSubscriptionType(event) {
            // Make all inputs disabled and reset value
            document.querySelectorAll('input[type="number"]').forEach((elem) => {
                elem.parentNode.parentNode.classList.remove('active');
            });

            // Make the specified input enabled
            event.target.parentNode.parentNode.classList.add('active');
        },
        error(msg) {
            Swal.fire(this.translations.error_title, (msg === undefined ? this.translations.error_text : msg), 'error');
        }
    },
    beforeCreate() {
        let pc = axios.get('ajax/personal-coffee?language=' + document.documentElement.lang);
        let s = axios.get('ajax/subscription?language=' + document.documentElement.lang);

        axios.all([pc, s]).then(axios.spread((...responses) => {
            if (responses[0].status === 200 && responses[0].data.coffee !== undefined) {
                this.coffee = responses[0].data.coffee;
            } else {
                this.error();
                console.error(`Something went wrong during AJAX request.`);
            }

            this.pricing = responses[1].data.pricing;

            this.translations = responses[1].data.translations;

            if (responses[1].data.subscription !== null) {
                this.subscription = responses[1].data.subscription;
            }
        })).catch(e => {
            this.error();
            console.error(`Something went wrong during AJAX request: ${e}`);
        });
    }
}
</script>

<style scoped>
.card {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 75%;
    padding: 25px 20px;
    margin: 20px 0;
    background-color: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    cursor: pointer;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.card:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, .5);

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.number-input {
    margin-bottom: 15px;
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    border-radius: 3px;
    width: 50px;
    padding: 10px;
    margin-right: 10px;
}

.number-input:disabled {
    background-color: rgba(168, 168, 168, 0.92);
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #caab8e;
    content: '';
    display: inline-block;
    visibility: visible;
}

small {
    color: #707070;
}

.subscription-item {
    padding: 20px;
}

.subscription-item .title {
    font-size: 18px;
}

.subscription-item input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
</style>
