import Notifications from 'vue-notification';
import Swal from 'sweetalert2';

window.Vue = require('vue');
window.Swal = Swal;

Vue.use(Notifications);

Vue.component('image-slider', require('./components/ImageSlider').default);
Vue.component('user-image-slider', require('./components/UserImageSlider').default);
Vue.component('personal-coffee', require('./components/PersonalCoffee').default);
Vue.component('shipping-info', require('./components/ShippingInfo').default);
Vue.component('subscription', require('./components/Subscription').default);
Vue.component('overview', require('./components/Overview').default);
Vue.component('payment-options', require('./components/PaymentOptions').default);
Vue.component('home-slider', require('./components/HomePageSlider').default);

const app = new Vue({
    el: '#app',
});
