<template>
    <div>
        <h2>{{page.title}}</h2>

        <div>
            <h4>{{page.subTitle}}</h4>

            <form v-on:submit.prevent="saveShippingData">
                <div class="col-lg-50" style="padding-right: 20px">
                    <label for="name" class="form-control form-block">{{form.fields.name}}</label>
                    <input type="text" id="name" ref="name" class="form-control form-block" tabindex="1" required>

                    <label for="address" class="form-control form-block">{{form.fields.address}}</label>
                    <input type="text" id="address" ref="address" class="form-control form-block" tabindex="3" required >

                    <label for="zip" class="form-control form-block">{{form.fields.zip}}</label>
                    <input type="text" id="zip" ref="zip" class="form-control form-block" tabindex="5" required >
                </div>

                <div class="col-lg-50" style="padding-right: 20px">
                    <label for="company" class="form-control form-block">{{form.fields.company}} ({{form.fields.optional}})</label>
                    <input type="text" id="company" ref="company" class="form-control form-block" tabindex="2">

                    <label for="city" class="form-control form-block">{{form.fields.city}}</label>
                    <input type="text" id="city" ref="city" class="form-control form-block" required tabindex="6">

                    <label for="country" class="form-control form-block">{{form.fields.country}}</label>
                    <select class="form-control form-block" id="country" ref="country" tabindex="8" required>
                        <option value="nl">Nederland</option>
                        <option value="be">België</option>
                    </select>
                </div>

                <div class="col-lg-100">
                    <div id="shipping_address_fields" style="display: none">
                        <div class="col-lg-100">
                            <h4>{{translations.shipping_address}}</h4>
                        </div>
                        <div class="col-lg-50" style="padding-right: 20px">
                            <label for="shipping_address" class="form-control form-block">{{form.fields.address}}</label>
                            <input type="text" id="shipping_address" class="form-control form-block" v-model="shipping_info_different.address" tabindex="9">

                            <label for="shipping_zip" class="form-control form-block">{{form.fields.zip}}</label>
                            <input type="text" id="shipping_zip" class="form-control form-block" v-model="shipping_info_different.zip" tabindex="11">
                        </div>

                        <div class="col-lg-50" style="padding-right: 20px">
                            <label for="shipping_city" class="form-control form-block">{{form.fields.city}}</label>
                            <input type="text" id="shipping_city" class="form-control form-block" v-model="shipping_info_different.city" tabindex="12">

                            <label for="shipping_country" class="form-control form-block">{{form.fields.country}}</label>
                            <select class="form-control form-block" id="shipping_country" v-model="shipping_info_different.country"  tabindex="14">
                                <option value="nl">Nederland</option>
                                <option value="be">België</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-100">
                        <input type="checkbox" id="same_address" v-on:change="toggleShippingAddress($event)" checked tabindex="15" /> <label for="same_address">{{form.fields.shipping_address_text}}</label>
                    </div>

                    <div class="col-lg-100">
                        <br/>
                        <h4>{{translations.create_account}}</h4>
                    </div>
                    <div class="col-lg-50" v-bind:class="{'error': errors.email !== undefined}" v-bind:style="errors.email !== undefined ? 'margin-bottom: 20px;padding-right: 20px':'padding-right: 20px'">
                        <label for="email" class="form-control form-block">{{form.fields.email}}</label>
                        <input type="email" id="email" ref="email" class="form-control form-block" required tabindex="16" v-bind:style="errors.email !== undefined ? 'margin-bottom: 0' : ''">
                        <small v-if="errors.email !== undefined" style="color: #d95c5c;">{{errors.email}}</small>
                    </div>
                </div>

                <div class="col-lg-100">
                    <div class="col-lg-50" style="padding-right: 20px">
                        <label for="password" class="form-control form-block">{{form.fields.password}}</label>
                        <input type="password" id="password" ref="password" class="form-control form-block" v-model="password" required tabindex="17">
                    </div>
                    <div class="col-lg-50" style="padding-right: 20px" v-bind:class="{'error': password !== password_confirm}">
                        <label for="repeat_password" class="form-control form-block">{{form.fields.repeat_password}}</label>
                        <input type="password" id="repeat_password" ref="repeat_password" class="form-control form-block" v-model="password_confirm" required tabindex="18">
                    </div>
                </div>

                <button class="btn btn-secondary" type="submit" tabindex="19" style="margin-top: 30px; float: right;" v-bind:class="{'disabled': password !== password_confirm}">{{translations.next}}</button>
            </form>
            &nbsp;<br>&nbsp;<br />&nbsp;
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "ShippingInfo",
    props: {
        url: String
    },
    data() {
        return {
            errors: {},
            translations: {shipping_address: "Loading..."},
            page: {
                title: "Loading...",
                subTitle: "Loading..."
            },
            form: {
                fields: {
                    name: "Loading...",
                    address: "Loading...",
                    zip: "Loading...",
                    company: "Loading...",
                    city: "Loading...",
                    country: "Loading...",
                    email: "Loading...",
                    password: "Loading...",
                    repeat_password: "Loading...",
                    optional: "Loading...",
                    shipping_address_text: "Loading..."
                },
                values: null
            },
            shipping_info_different: {
                address: null,
                zip: null,
                city: null,
                country: null,
            },
            password: null,
            password_confirm: null
        }
    },
    methods: {
        setValues() {
            let inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                let id = inputs[i].id;
                if (this.form.values[id] !== null && this.form.values[id] !== undefined) {
                    if (id.startsWith('shipping_')) {
                        this.shipping_info_different[id.split('_')[1]] = this.form.values[id];
                        // Something in the shipping address has been filled in so uncheck the checkmark
                        document.getElementById('same_address').checked = false;
                        document.getElementById('shipping_address_fields').style.display = "block";
                    }
                    inputs[i].value = this.form.values[id];
                }
            }
        },
        toggleShippingAddress(event) {
            if (event.target.checked === true) {
                document.getElementById('shipping_address_fields').style.display = "none";

                // Make the fields empty to make sure that no shipping data is stored if the checkbox is full
                this.shipping_info_different.address = null;
                this.shipping_info_different.address2 = null;
                this.shipping_info_different.zip = null;
                this.shipping_info_different.state = null;
                this.shipping_info_different.city = null;
                this.shipping_info_different.country = null;
            } else {
                document.getElementById('shipping_address_fields').style.display = "block"
            }
        },
        saveShippingData() {
            if (this.password === this.password_confirm) {
                let formData = new FormData();
                formData.append('invoice_and_shipping[0]', JSON.stringify({
                    name: this.$refs.name.value,
                    address: this.$refs.address.value,
                    zip: this.$refs.zip.value,
                    company: this.$refs.company.value,
                    city: this.$refs.city.value,
                    country: this.$refs.country.value,
                    shipping_address: this.shipping_info_different.address,
                    shipping_zip: this.shipping_info_different.zip,
                    shipping_city: this.shipping_info_different.city,
                    shipping_country: this.shipping_info_different.country
                }));

                formData.append('account[0]', JSON.stringify({
                    email: this.$refs.email.value,
                    password: this.$refs.password.value,
                    repeat_password: this.$refs.repeat_password.value,
                }));

                axios.post('ajax/save-shipping-info?language=' + document.documentElement.lang, formData)
                    .then(response => {
                        if (response.data.status === 200) {
                            location.href = this.url;
                        } else {
                            if (response.data.status.startsWith('It seems that the email')) {
                                this.errors = {
                                    email: response.data.status
                                };
                            } else {
                                this.error(response.data.status);
                            }
                            console.error(`Something went wrong during AJAX request.`);
                        }
                    })
                    .catch(e => {
                        this.error();
                        console.error(`Something went wrong during AJAX request: ${e}`);
                    })
            }
        },
        error(msg) {
            Swal.fire(this.translations.error_title, (msg === undefined ? this.translations.error_text : msg), 'error');
        }
    },
    beforeCreate() {
        axios.get('ajax/shipping-info?language=' + document.documentElement.lang)
            .then(response => {
                if (response.status === 200) {
                    this.translations = response.data.translations;
                    this.page = response.data.page;
                    this.form = response.data.form;
                    if (response.data.form.values !== null) {
                        this.setValues();
                    }
                } else {
                    this.error();
                    console.error(`Something went wrong during AJAX request.`);
                }
            })
            .catch(e => {
                this.error();
                console.error(`Something went wrong during AJAX request: ${e}`);
            });
    },
}
</script>

<style scoped>
.body_order h4 {
    margin-bottom: 32px;
}

.disabled {
    cursor: default;
    background-color: #707070;
}

.disabled:hover {
    background-color: #707070;
}

input {
    margin-bottom: 20px;
}
</style>
